let $ = jQuery.noConflict();
$(($) => {
  const duration = 600;
  const header = $('.c_header_mobile_modal');
  const commonCTA = $('.e25-section-common-bottom-cta');
  const headerInner = header.find('> .ct-section-inner-wrap');
  // for styling convenience id is removed
  header.find('.oxy-slide-menu ').removeAttr('id');
  header
    .find('.oxy-slide-menu_list > .menu-item-has-children')
    .append('<span class="header-drop-down-helper"></span>');
  const headerDropDownHelper = header.find(
    '.oxy-slide-menu_list > .menu-item-has-children span.header-drop-down-helper'
  );
  headerDropDownHelper.on('click', (e) => {
    const $this = $(e.target);
    const li = $this.parent();
    const isExpanded = li.hasClass('header-expanded');
    header
      .find('.header-expanded')
      .removeClass('header-expanded')
      .find('>ul.sub-menu')
      .slideUp(duration);
    if (isExpanded) {
      $this.prev('ul.sub-menu').slideUp(duration);
    } else {
      li.addClass('header-expanded');
      $this.prev('ul.sub-menu').slideDown(duration);
    }
  });
  if(commonCTA.length > 0) {
    header.addClass('header-with-cta');
    headerInner.prepend(commonCTA);
  }
});
