import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
(($) => {
  const stickyCardsSection = $('.e25-section-sticky-cards');
  const stickyCards = $('.e25-div-sticky-card');
  const bgColors = ['#726f98', '#474378'];

  const calcHeights = () => {
    const maxHeight = Math.max.apply(null, $(stickyCards).map((ind) => {
      return $(stickyCards).eq(ind).outerHeight();
    }).get());
    stickyCards.css({ 'height': maxHeight });
    const stickyCardsSectionHeight = stickyCardsSection.outerHeight();
    stickyCardsSection.attr('data-height', stickyCardsSectionHeight);
  };

  const stickyCardsFunc = () => {
    if ($(window).width() < 768 || stickyCards.length === 0) {
      return;
    }
    calcHeights();

    const stickyCardsGsap = gsap.utils.toArray('.e25-div-sticky-card:not(:last-child)');
    const stickyCardsTItleGsap = gsap.utils.toArray('.e25-div-sticky-card:not(:last-child) h2');
    const scaleDifference = 0.07;
    const hH = $('header').outerHeight();
    const stickySectionPaddingTop = parseInt(stickyCardsSection.css('padding-top'));

    stickyCardsGsap.forEach((stickyCard, index) => {
      const startPosition = (index * 64) + stickySectionPaddingTop + hH;
      const scaleValue = scaleDifference * (stickyCards.length - (index + 1));
      const cardHeight = $(stickyCard).outerHeight();
      gsap.to(stickyCard, {
        scale: 1 - scaleValue,
        transformOrigin: 'top center',
        backgroundColor: bgColors[index % 2],
        duration: 1,
        scrollTrigger: {
          trigger: stickyCard,
          start: `top ${startPosition}`,
          end: `+=${cardHeight}`,
          scrub: 0.8,
          invalidateOnResize: true,
          onLeave: () => {
            if (index == stickyCardsGsap.length - 1) {
              stickyCardsSection.addClass('remove-sticky').css('height', stickyCardsSection.data('height'));
            }
          },
          onEnterBack: () => {
            if (index == stickyCardsGsap.length - 1) {
              stickyCardsSection.removeClass('remove-sticky').height('auto');
            }
          },
        },
      });
    });

    stickyCardsTItleGsap.forEach((title, index) => {
      const startPosition = index * 64 + 217;
      const endPosition = index * 64;
      gsap.to(title, {
        duration: 1,
        scrollTrigger: {
          trigger: title,
          start: `top ${startPosition}`,
          end: `bottom ${endPosition}`,
          scrub: 0.8,
          onLeave: () => {
            $(title).parents('.e25-div-sticky-card').addClass('animation-done');
          },
          onEnterBack: () => {
            $(title).parents('.e25-div-sticky-card').removeClass('animation-done');
          },
        },
      });
    });
  };

  $(window).on('load', () => {
    stickyCardsFunc();
  });
})(jQuery);