(($) => {
    const setEqualHeight = (elements) => {
      const ele = $(elements);
      const heights = ele.map((index, element) => $(element).css('height', 'auto').height()).get();
      const maxHeight = Math.max(...heights);
      ele.height(maxHeight);
    };
  
    const processBenefitsCard = () => {
      const winWidth = document.documentElement.clientWidth;
      const sectionBenefits = $('.e25-section--partner-benefits-with-coro');
  
      sectionBenefits.each((index, element) => {
        const $this = $(element);
        const benefitsCard = $this.find('.benefits-card');
  
        if (winWidth > 767) {
          $.each(benefitsCard, (index) => {
            if (index % 3 === 0) {
              setEqualHeight(benefitsCard.slice(index, index + 3).find('h3'));
              setEqualHeight(benefitsCard.slice(index, index + 3).find('p.ct-text-block'));
              setEqualHeight(benefitsCard.slice(index, index + 3).find('.oxy-rich-text'));
            }
          });
        } else {
          benefitsCard.find('h3, p.ct-text-block, .oxy-rich-text').removeAttr('style');
        }
      });
    };
  
    const processPricingCards = () => {
      const winWidth = document.documentElement.clientWidth;
      const sectionPricingCards = $(
        'body:not(.oxygen-builder-body) .e25-section-pricing-cards .e25-slider-hm-suites'
      );
  
      sectionPricingCards.each((index, element) => {
        const $this = $(element);
        const childPricingElements = $this.find('.e25-div-hm-suites');
  
        if (winWidth > 992) {
          $.each(childPricingElements, (index) => {
            if (index % 4 === 0) {
              setEqualHeight(childPricingElements.slice(index, index + 4).find('.e25-div-hm-suites-content > p'));
              setEqualHeight(childPricingElements.slice(index, index + 4).find('.price-box').parent());
              setEqualHeight(childPricingElements.slice(index, index + 4).find('.e25-div-hm-suites-logo'));
            }
          });
        } else {
          childPricingElements.find(
            '.e25-div-hm-suites-content > p, .e25-div-hm-suites-content > .ct-div-block, .e25-div-hm-suites-logo'
          ).removeAttr('style');
        }
      });
    };
  
    const handleToggleBtnChange = () => {
      let timer;
      const toggleBtn = $('.e25-section-pricing-toggle-btn .oxy-toggle-switch');
  
      if (toggleBtn.length !== 0) {
        toggleBtn.on('change', () => {
          setTimeout(() => addEqualHeight());
        });
      }
  
      $(window).on('resize', () => {
        clearTimeout(timer);
        timer = setTimeout(() => addEqualHeight(), 10);
      });
    };
  
    const addEqualHeight = () => {
      processBenefitsCard();
      processPricingCards();
    };
  
    $(window).on('load', () => {
      addEqualHeight();
      handleToggleBtnChange();
    });
  })(jQuery);