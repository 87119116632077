(($) => {
    class HexagonIntersect {
      constructor (cards) {
        this.cards = $(cards);
        this.top = ($(window).width() > 768) ? 192 : 119;
      }
  
      calcPositions() {
        this.cards.each((index, card) => {
          card = $(card);
          const hexagon = card.find('.card-hexagon');
          const offsetTop = card.position().top;
          const offsetLeft = card.position().left;
          hexagon.css({
            left: `-${offsetLeft}px`,
            top: `${this.top - offsetTop}px`,
          });
        });
      }
      init() {
        this.cards.each((index, card) => {
          $(card).append('<span class="card-hexagon"></span>');
        });
        this.calcPositions();
      }
    }
  
    const cards = $('.e25-slider-hm-suites .e25-div-hm-suites');
    const cardsIntersect = new HexagonIntersect(cards);
    cardsIntersect.init();
    $(window).on('resize', () => {
      cardsIntersect.calcPositions();
    });
  })(jQuery);