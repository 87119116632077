import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
(($) => {
  if (window.innerWidth > 768) {
    const animatedSections = document.querySelectorAll('.c_sect_zoom');
    const startPosition = 97;
    const refreshAOS = (elements) => {
      const nonAnimatedElements = $(elements);
      nonAnimatedElements.each((i, element) => {
        /* eslint-disable-next-line no-undef */
        AOS.init({ target: element, once: true});
        setTimeout(() => {
          if(element.getBoundingClientRect().top > $(window).height()) {
            $(element).addClass('not-in-view animated');
          }
        }, 100);
      });
    };
    const onEnterFunc = (self) => {
      const nextSection = $(self.spacer).next();
      const nextAfterSection = nextSection.next();
      const nextSectionTop= self.pin.clientHeight + startPosition;
      nextSection.css({
        'marginTop': 0,
        'top': `${nextSectionTop}px`,
        'width': '100%',
        'position': 'fixed',
      });
      nextAfterSection.css({
        'display': 'block',
        'paddingTop': `${nextSection.outerHeight()}px`,
      });
      ScrollTrigger.refresh(true);
      ScrollTrigger.config({
        autoRefreshEvents: 'visibilitychange,DOMContentLoaded,load,orientationchange'
      });
      refreshAOS($('.aos-init:not(.animated)'));
    };

    const onLeaveFunc = (self) => {
      const nextSection = $(self.spacer).next();
      const nextAfterSection = nextSection.next();
      nextSection.removeAttr('style');
      nextAfterSection.removeAttr('style');
      ScrollTrigger.config({
        autoRefreshEvents: 'rezise,visibilitychange,DOMContentLoaded,load,orientationchange'
      });
    };

    const onLeaveBackFunc = (self) => {
      const nextSection = $(self.spacer).next();
      const nextAfterSection = nextSection.next();
      const nextSectionOffset = self.spacer.clientHeight - self.pin.clientHeight;
      nextSection.removeAttr('style');
      nextSection.css({
        'marginTop': `-${nextSectionOffset}px`,
      });
      nextAfterSection.removeAttr('style');
      ScrollTrigger.config({
        autoRefreshEvents: 'resize, visibilitychange,DOMContentLoaded,load,orientationchange'
      });
    };

    if (animatedSections.length > 0) {
      animatedSections.forEach((animatedSection) => {
        // add this dummy section to prevent issues when there are more than one section
        $('<span class="next-pin-spacer"></span>').insertAfter($(animatedSection).next());
        gsap.to(animatedSection, {
          scale: 0.93,
          borderRadius: '40px',
          transformOrigin: 'center center', 
          duration: 2,
          scrollTrigger: {
            trigger: animatedSection,
            start: `top ${startPosition}px`,
            end: 'top -30%',
            pin: true,
            scrub: 1,
            toggleClass: 'section-stick',
            onEnter: (self) => {
              onEnterFunc(self);
            },
            onEnterBack: (self) => {
              onEnterFunc(self);
            },
            onLeave: (self) => {
              onLeaveFunc(self);
            },
            onLeaveBack: (self) => {
              onLeaveBackFunc(self);
            },
            onRefresh: (self) => {						
              const nextSection = $(self.spacer).next();
              const nextSectionOffset = self.spacer.clientHeight - self.pin.clientHeight;
              if(self.progress == 0) {
                nextSection.css({
                  'marginTop': `-${nextSectionOffset}px`,
                });
              } else if(self.progress >= 1) {
                onLeaveFunc(self);
              } else {
                onEnterFunc(self);
              }
            }
          },
        });
      });
    }
  }
})(jQuery);
