let $ = jQuery.noConflict();
$($ => {
  $.fn.advancedAccordion = function (options) {
    const settings = $.extend(
      {
        panelClass: 'oxy-tab',
        bodyClass: 'advanced-accordion__body',
        contentClass: 'oxy-tab-content',
        contentWrapperClass: 'oxy-tabs-contents-wrapper',
      },
      options
    );

    const setContentIn = advancedAccordion => {
      const div = advancedAccordion.find(`.${settings.contentWrapperClass}`);
      advancedAccordion.find(`.${settings.bodyClass}`).each((i, panel) => {
        const topContent = div
          .find('.oxy-tab-content')
          .eq(i)
          .clone()
          .removeClass('oxy-tabs-contents-content-hidden')
          .get(0);
        panel.appendChild(topContent);

      });
    };

    const scrollToClickedCard = (card) => {
      const cardParent = $(card).parent(),
        cardParentBox = cardParent[0].getBoundingClientRect(),
        wH = $(window).height();
      if (cardParentBox.bottom > wH) {
        const cardHeight = cardParentBox.height,
          cardOffsetTop = cardParent.offset().top;
        $('html, body').animate({ scrollTop: cardOffsetTop - (wH - cardHeight - 60) }, 600);
      }
    };

    $(this).each((i, advancedAccordion) => {
      $(advancedAccordion).find(`.${settings.panelClass}`).eq(0).addClass('tab-is-active');
      $(advancedAccordion).find(`.${settings.panelClass}`).on('click', e => {
        const clickedTab = $(e.target);
        const isActive = clickedTab.hasClass('tab-is-active');
        const clickedContent = clickedTab.find(`.${settings.bodyClass}`);

        if (isActive) {
          clickedTab.removeClass('tab-is-active');
          clickedContent.slideUp(600);
        } else {
          $(advancedAccordion).find(`.${settings.panelClass}`).removeClass('tab-is-active');
          clickedTab.addClass('tab-is-active');
          $(advancedAccordion).find(`.${settings.bodyClass}`).slideUp(600);
          clickedContent.slideDown(600, () => {
            scrollToClickedCard(clickedContent);
          });
        }
      });

      $(window).on('load', () => {
        setContentIn($(advancedAccordion));
      });
    });

  };

  $('.div-tabs-wrapper').advancedAccordion();
});

