const debounce = require('lodash.debounce');

// Assigning debounce as a global object
window.debounce = debounce;

let $ = jQuery.noConflict();
$(($) => {
  $.fn.observeElements = function () {
    const bottomRootMargin =
      $(window).height() > $(window).width() ? '-8%' : '-16%';
    $(this).each((i, element) => {
      const observer = new IntersectionObserver(
        (entries) => {
          const entry = entries[0];
          if (entry.isIntersecting) {
            element.classList.add('e25-appeared');
            observer.disconnect();
          }
        },
        {
          root: null,
          rootMargin: '0px 0px ' + bottomRootMargin + ' 0px',
        }
      );
      observer.observe(element);
			
      const videos = $(element).find('video');
      videos.each((ind, video) => {
        $(video).removeAttr('controlls');
        video.pause();
        const videoObserver = new IntersectionObserver(
          (entries) => {
            const entry = entries[0];
            if (entry.isIntersecting) {
              video.play();
            }else {
              video.pause();
            }
          }
        );
        videoObserver.observe(video);
      });
    });
  };
  $(
    '.e25-section-observe, .e25-section--observe, .e25-div-observe'
  ).observeElements();
});
