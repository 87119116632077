(($) => {
    $.fn.marqueePlugin = function () {
      const marqueeRows = this;
      const width4K = 7680;
      let totalLogoCount = 0;
          
      // Calculate the total number of logo items in all rows
      marqueeRows.each((ind, ele) => {
        const logoCount = $(ele).find('.js-blurb-moving').length;
        totalLogoCount += logoCount;
      });
          
      // Calculate the marquee speed based on the total count
      const marqueeDefaultSpeed = 300;
      const marqueeSpeed = (parseFloat(marqueeDefaultSpeed) / totalLogoCount);
          
      // Apply the speed to each row
      marqueeRows.each((ind, ele) => {
        const marqueeRow = $(ele);
        const marqueeItems = marqueeRow.find('.js-blurb-moving');
        const marqueeItemsCount = marqueeItems.length;
        const rowMarqueeSpeed = marqueeSpeed * marqueeItemsCount;
        const marqueeRowWidth = marqueeRow.find('.js-moving-slider-wrapper').width();
        let appendTimes = Math.ceil(width4K / marqueeRowWidth);
  
        appendTimes = (appendTimes % 2 === 1) ? appendTimes : appendTimes + 1;
  
        for(let i = 0; i < appendTimes; i++) {
          marqueeRow
            .find('.js-moving-slider-wrapper')
            .append(marqueeItems.clone());
        }
              
        marqueeRow
          .find('.js-moving-slider-wrapper')
          .addClass('start-marquee')
          .css('animation-duration', `${rowMarqueeSpeed}s`);
      });
          
      marqueeRows.on('mouseover mouseleave', '.js-blurb-moving', function (e) {
        const targetElem = $(this);
        const targetElemLink = targetElem.find('a').length;
              
        if (e.type === 'mouseover') {
          targetElem.parent().toggleClass('continue-marquee', !targetElemLink);
        } else if (e.type === 'mouseleave' && !targetElemLink) {
          targetElem.parent().removeClass('continue-marquee');
        }
      });
          
      return this;
    };
    if ($('body').hasClass('oxygen-builder-body')) return;
    $('.js-moving-slider').marqueePlugin();
      
    if ($(window).width() < 768) {
      $('.js-logo-moving-slider').marqueePlugin();
    }
  })(jQuery);