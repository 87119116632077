import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
(($) => {
  $(document).ready(function () {
    const stickyCardsSection = $('.c_sect_scroll_tab');
    const stickyCards = $('.c_sect_scroll_tab .oxy-tabs-contents-wrapper .oxy-tab-content');
    const stickyTabs = $('.c_sect_scroll_tab .oxy-tabs-wrapper .oxy-tab');
    let isClicked = false;
    if($(window).width() < 768 || stickyCards.length === 0) {
      return;
    }

    const stickyCardsSectionHeight = stickyCardsSection.outerHeight();
    stickyCardsSection.attr('data-height', stickyCardsSectionHeight);

    const stickyCardsGsap = 
    gsap.utils.toArray('.c_sect_scroll_tab .oxy-tabs-contents-wrapper .oxy-tab-content');


    stickyCardsGsap.forEach((stickyCard, index) => {
      $(stickyTabs).eq(0).addClass('active');
      const cardHeight = stickyCard.offsetHeight;
      function activateStickyCard(isClicked) {
        $(stickyCards).removeClass('active');
        $(stickyTabs).removeClass('active');
        if (!isClicked) {
          $(stickyCard).addClass('active');
          $(stickyTabs).eq(index).addClass('active');
        }
      }  
      gsap.to(stickyCard, {
        transformOrigin: 'top center',
        duration: 1,
        scrollTrigger: {
          trigger: stickyCard,
          start: 'top 260px',
          end: `+=${cardHeight}`,
          scrub: 0.8,
          onEnter: () => {
            activateStickyCard(isClicked);
          },
          onEnterBack: () => {
            activateStickyCard(isClicked);
          },
        },
      });
    });

    stickyTabs.on('click', (e) => {
      isClicked = true;
      const clickedIndex = Array.from(stickyTabs).indexOf(e.currentTarget);
      //console.log('ClickedIndex:'+clickedIndex);
      const clickedCard = stickyCards.eq(clickedIndex);
      //console.log('Clickedcard'+clickedCard);
      //console.log('ClickedcardOffsetTop'+clickedCard.offset().top);
      //console.log('Header Height'+$('header').height() + 25);
      $('html').animate({
        scrollTop: clickedCard.offset().top - ($('.coro-mega-menu').height() + 25)
      },100, function(){
        setTimeout(()=> {
          $(stickyCards).removeClass('active');
          $(stickyTabs).removeClass('active');
          $(stickyCards).eq(clickedIndex).addClass('active');
          $(stickyTabs).eq(clickedIndex).addClass('active');
          isClicked = false;
        },500);
      });
    });
  });
})(jQuery);