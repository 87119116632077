let $ = jQuery.noConflict();
$(($) => {
  const duration = 600;
  const filterP = $('.e25-div--filter p');
  const filterDiv = $('.e25-div--filter');
  if (filterDiv.length == 0) {
    return;
  }
  filterDiv.find('.facetwp-type-checkboxes').first().slideDown(duration);
  filterP.on('click', (e) => {
    const $this = $(e.target);
    const div = $this.parent();
    const isExpanded = div.hasClass('filter-expanded');
    if (isExpanded) {
      div.removeClass('filter-expanded');
      div.find('.facetwp-type-checkboxes').slideUp(duration);
    } else {
      div.find('.facetwp-type-checkboxes').slideDown(duration);
      div.addClass('filter-expanded');
    }
  });
});
