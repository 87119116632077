(($) => {
    class E25FlickitySlider {
      constructor (sliders) {
        this.sliders = $(sliders);
        this.initialisedCountUp = {}; // Object to store initialized count-ups
        this.initialised = false;// Object to store whether countUp has been initialized for each element
        this.countersArr = [];
      }
  
      getFlickityObjectName (obj) {
        const propertyNames = Object.keys(obj);
        const searchString = 'jQuery';
        const names = propertyNames.filter(name => name.startsWith(searchString));
        const propertyName = names[1];
  
        return propertyName;
      }
  
      addProgressBar (slider, slidesCount, activeSlideIndex) {
        const progressBarHTML = `
                  <div class="e25-slider-progress" data-length="${slidesCount}">
                      <div class="e25-slider-progress__bar">
                          <span class="e25-slider-progress__anim" 
                          style="width:${((activeSlideIndex + 1) / slidesCount) * 100}%"></span>
                      </div>
                      <div class="e25-slider-progress__counter-wrapper">
                          <span class="active-slide-id">
                          ${activeSlideIndex + 1}</span>/<span class="slide-count">${slidesCount}
                          </span>
                      </div>
                  </div>
              `;
        slider.append(progressBarHTML);
      }
  
      updateProgressBar (progressAnimationBar, activeSlideIndex, slidesCount) {
        progressAnimationBar.css({
          width: ((activeSlideIndex + 1) / slidesCount) * 100 + '%',
        });
        progressAnimationBar
          .closest('.e25-slider-progress')
          .find('.active-slide-id')
          .text(activeSlideIndex + 1);
      }
  
      //queue Counters
      queueCountUp(counters) {
        $(counters).each((ind, counter) => {
          let currCounter = $(counter);
          let endValue = parseFloat(currCounter.attr('data-end'));
          currCounter.attr({ 'data-updated': endValue, 'data-end': 0, 'data-index': ind });
          let updateValue = parseFloat(currCounter.attr('data-updated'));
          const options = {
            startVal: parseFloat(currCounter.attr('data-start')),
            decimalPlaces: parseInt(currCounter.attr('data-decimals')),
            duration: parseInt(currCounter.attr('data-duration')),
            useEasing: currCounter.attr('data-easing') === 'true',
            useGrouping: currCounter.attr('data-grouping') === 'true',
            separator: currCounter.attr('data-separator'),
            decimal: currCounter.attr('data-decimal'),
            smartEasingThreshold: parseFloat(currCounter.attr('data-threshold')),
            smartEasingAmount: parseFloat(currCounter.attr('data-amount')),
            onCompleteCallback: (element) => {
              element.target.update();
            }
          };
          //eslint-disable-next-line no-undef
          let numAnim = new countUp.CountUp(currCounter[0], parseFloat(updateValue), options);
          this.countersArr.push(numAnim);
        });
      }
      // Function to reset and start countUp for an element
      resetAndStartCountUp(counter, status) {
        const ind = $(counter).attr('data-index'),
          endValue = parseFloat($(counter).attr('data-updated'));
        if (status === 'start') {
          setTimeout(() => {
            if (!this.countersArr[ind].error) {
              this.countersArr[ind].update(parseFloat(endValue));
            }
          }, 600);
        } else if (status === 'reset') {
          this.countersArr[ind].reset();
        }
      }
          
      flickitySliderOnInit (event) {
        const currObjectName = this.getFlickityObjectName($(event.target).flickity()[0]),
          currObject = $(event.target).flickity()[0][currObjectName].flickity,
          currSliderLength = currObject.cells.length,
          currSliderActiveSlide = currObject.selectedIndex,
          currSlider = $(event.target),
          currSliderParent = currSlider.parent(),
          hasProgressBar = currSliderParent.hasClass('e25-slider--with-progress-bar'),
          counters = $('.oxy-counter_digit'),
          currentSlide = currSlider.find('.cell').eq(currSliderActiveSlide),
          currentSlideCountersCount = currentSlide.find('.oxy-counter_digit').length;
        currentSlide.addClass('counters-animated');
        if(!(currSliderLength > 1)) {
          currSliderParent.addClass('hide-arrows');
          currObject.unbindDrag();
        }
        if(hasProgressBar) {
          this.addProgressBar (currSlider, currSliderLength, currSliderActiveSlide);
        }
        if(counters.length > 0) {
          this.queueCountUp(counters.slice(currentSlideCountersCount));
        }
      }
  
      flickitySliderOnChange (event, index) {
        $(event.target).parent().addClass('slide-changing');
        setTimeout(() => {
          $(event.target).parent().removeClass('slide-changing');
        }, 200);
        const currProgressBar = $(event.target).find('.e25-slider-progress');
        const hasProgressBar = !currProgressBar.length == 0;
        let $nextSlide = $(event.target).find('.cell').eq(index);
        let $counters = $nextSlide.find('.oxy-counter_digit');
  
        if(hasProgressBar) {
          const currProgressAnimationBar = currProgressBar.find('.e25-slider-progress__anim'),
            currSlidesLength = currProgressBar.data('length');
          this.updateProgressBar(currProgressAnimationBar, index, currSlidesLength);
        }
        if(!($nextSlide.hasClass('counters-animated'))) {
          $counters.each((index, element) => {
            let $this = $(element);
            this.resetAndStartCountUp($this, 'start');
            $nextSlide.addClass('counters-animated');
          });
        }
        
      }
  
      flickitySliderOnSettle (event) {
        $(event.target).parent().removeClass('slide-changing');
      }
  
      // add this to fix the lightbox issue inside the carousal, used same class for init the slider
      fixLightBoxIssue () {
        this.sliders.each((index, ele) => {
          const slides = $(ele).find('.cell');
          slides.find('.oxy-lightbox .oxy-lightbox_inner').removeClass('oxy-inner-content');
        });
      }
          
      initReady() {
        this.fixLightBoxIssue();
        this.sliders.on('ready.flickity', (event) => {
          this.flickitySliderOnInit(event);
        });
      }
      init() {
        this.sliders.on('change.flickity', (event, index) => {
          this.flickitySliderOnChange(event, index);
        }).on('settle.flickity', (event, index) => {
          this.flickitySliderOnSettle(event, index);
        });
      }
    }
    const flickitySlidersArr = [];
    $('.oxy-carousel-builder_inner').each((index, element) => {
      const flickitySliders = new E25FlickitySlider(element);
      flickitySlidersArr.push(flickitySliders);
      flickitySliders.initReady();
    });
      
    $(window).on('load', () => {
      flickitySlidersArr.forEach((slider) => {
        slider.init();
      });
    });
  })(jQuery);